<script>
  import { createEventDispatcher } from 'svelte';

  export let actions = [];
  export let config = {};
  export let customOptionsRenderer;
  export let options = [];
  export let selectedLabel;
  export let selectedOption;
  export let isMobile;
  export let getNodeName;
  export let getRouteLink;
  export let getTestId;
  export let getTranslation;
  const dispatch = createEventDispatcher();
  export function onActionClick(node) {
    dispatch('onActionClick', { node });
  }

  export function goToOption(option, selectedOption) {
    dispatch('goToOption', { option, selectedOption });
  }
</script>

<nav class="fd-menu lui-ctx-switch-nav {isMobile ? 'fd-menu--mobile' : ''}">
  {#if actions && actions.length}
    <ul class="fd-menu__list fd-menu__list--top">
      {#each actions as node}
        {#if node.position === 'top' || !['top', 'bottom'].includes(node.position)}
          <li
            class="fd-menu__item"
            on:click={() => onActionClick(node)}
            data-testid={getTestId(node)}
          >
            <a
              href={getRouteLink(node)}
              on:click|preventDefault={() => {}}
              class="fd-menu__link"
            >
              <span class="fd-menu__title">{$getTranslation(node.label)}</span>
            </a>
          </li>
        {/if}
      {/each}
    </ul>
  {/if}
  <ul class="fd-menu__list" id="context_menu_middle">
    {#if options && options.length === 0}
      <li class="lui-contextswitcher-indicator">
        <div class="fd-busy-indicator" aria-hidden="false" aria-label="Loading">
          <div class="fd-busy-indicator--circle-0" />
          <div class="fd-busy-indicator--circle-1" />
          <div class="fd-busy-indicator--circle-2" />
        </div>
      </li>
    {/if}
    {#if options && options.length}
      {#each options as node}
        {#await getNodeName(node.label, config.fallbackLabelResolver, node.id) then label}
          <li
            class="fd-menu__item"
            on:click={() => goToOption(node, selectedOption)}
            data-testid={getTestId(node)}
          >
            {#if customOptionsRenderer}
              {@html customOptionsRenderer(node, label === selectedLabel)}
            {:else}
              <a
                href={getRouteLink(node)}
                on:click|preventDefault={() => {}}
                class="fd-menu__link {label === selectedLabel
                  ? 'is-selected'
                  : ''}"
                title={label}
              >
                <span class="fd-menu__title">{label}</span>
              </a>
            {/if}
          </li>
        {/await}
      {/each}
    {/if}
  </ul>
  {#if actions && actions.length}
    <ul class="fd-menu__list fd-menu__list--bottom">
      {#each actions as node}
        {#if node.position === 'bottom'}
          <li
            class="fd-menu__item"
            on:click={() => onActionClick(node)}
            data-testid={getTestId(node)}
          >
            <a
              href={getRouteLink(node)}
              on:click|preventDefault={() => {}}
              class="fd-menu__link"
            >
              <span class="fd-menu__title">{$getTranslation(node.label)}</span>
            </a>
          </li>
        {/if}
      {/each}
    </ul>
  {/if}
</nav>

<style type="text/scss">:global(.fd-popover__body) .lui-ctx-switch-nav {
  max-height: calc(100vh - 76px);
  overflow-y: auto; }

.fd-menu__list--bottom {
  border-top: var(--sapList_BorderWidth, 0.0625rem) solid var(--sapList_BorderColor, #e4e4e4);
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fd-menu__list--top {
  border-bottom: var(--sapList_BorderWidth, 0.0625rem) solid var(--sapList_BorderColor, #e4e4e4);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

:global(.lui-contextswitcher-indicator) {
  padding: 20px 0;
  text-align: center; }

/*# sourceMappingURL=x.map */</style>
